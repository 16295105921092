// User Profile Avatars & Pictures
.profile-img-topbar {
  @include profile-img(62px, 62px, 0 0.5rem 0 auto);
}

.profile-img-large {
  @include profile-img(112px, 112px, 0);
}

.profile-img-medium {
  @include profile-img(62px, 62px, 0);
}

.profile-img-small {
  @include profile-img(34px, 34px, 0);
}