.chonky-searchFieldInputInner {
  border: unset;
}

.chonky-searchFieldInputInner:focus {
  border: unset;
  background-color: unset;
  box-shadow: unset;
  transition: unset;
}

.chonky-baseButton {
  color: $black;
}
