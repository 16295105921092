// steps as graphics to help the user know where they are in a series of forms
.step {
  height: 30px;
  width: 30px;
  margin: 0 15px;
  background-color: transparent;
  border: 1px solid $primary-color;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
  color: $primary-color;
  text-align: center;
  &.active {
    opacity: 1;
    background-color: $primary-color;
    color: #fff;
  }
  &.finished {
    background-color: $primary-color;
    color: #fff;
  }
}