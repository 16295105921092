i.alert {
	color: get-color(alert);
}

i.success {
	color: $success-color;
}

i.warning {
	color: $warning-color;
}