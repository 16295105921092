.note-r-notes-list {
  max-height: 1200px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  .note-r-notes-list-item {
    min-height: 92px;
    &.Mui-disabled {
      opacity: unset;
    }
  }
}
.note-r-note-display {
  max-height: 1200px;
  min-height: 330px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
