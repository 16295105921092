.outlined-hover-panel {
  @include callout(#fff);
  padding:20px;
  border: 1px solid rgba(#ccc, 0.5);
  color: $dark-gray;
  cursor: pointer;

  &.selected {
    border: 1px solid $primary-color;
  }

  [class*="block-grid-"] > li {
    padding-top: rem-calc(5);
    padding-bottom: rem-calc(5);
  }

  @include panel-hover();
}