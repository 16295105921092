.label {
  
  font-weight: normal;
  
  .fa {line-height: 0 !important;}

  &.reversed {
    background: #fff;
    border: 1px solid $primary-color;
    color: $primary-color;
  }

  &.width-limited {
    max-width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.clear {
    background: #fff;
    border: none;
    color: #000;
  }

  &.success,
  &.awarded {
    border: 1px solid $success-color;
    background-color: $success-color;
    &.reversed {
      background: #fff;
      border: 1px solid $success-color;
      color: $success-color;
    }
  }

  &.secondary {
    border: 1px solid $secondary-color;

    &.reversed {
      background: #fff;
      border: 1px solid $secondary-color;
      color: #000;
    }
  }

  &.alert,
  &.awaiting-signature,
  &.awaiting-bid {
  border: 1px solid get-color(alert);
  background-color: get-color(alert);
    &.reversed {
      background: #fff;
      border: 1px solid get-color(alert);
      color: #000;
    }
  }

  &.info,
  &.in-progress {
    border: 1px solid #a0d3e8;
    background-color: #a0d3e8;
    color: #333333;

    &.reversed {
      background: #fff;
      border: 1px solid #a0d3e8;
      color: #000;
    }
  }
}

.label-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  &::before, &::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1;
  }

  &::after {
    clear: both;
  }

  .label {
    margin: 0;
    margin-right: 4px;
    margin-bottom: 4px;
    flex: 0 0 auto;
  }

  .label:last-child {
    margin-right: 0;
  }
}