li {

  .tab-counter {
    background-color: scale-color(#efefef, $lightness: 35%);
    color: $dark-gray;
    width: 0.7rem;
    height: 0.7rem;
    line-height: 0.7rem;
    font-size: 0.4rem;
    text-align: center;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    zoom: 1;
    *display: inline;
    @include border-radius(50%);
  }

  &.is-active {

    .tab-counter {
      background-color: $primary-color;
      color: #fff;
    }
  }
}

span.counter {
  background-color: $primary-color;
  &.alert {
    background-color: get-color(alert);
  }
  &.success {
    background-color: $success-color;
  }
  &.warning {
    background-color: $warning-color;
  }
  color: #fff;
  width: 1.125rem;
  height: 1.125rem;
  line-height: 1.125rem;
  font-size: 0.6125rem;
  text-align: center;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  @include border-radius(50%);
  &.has-tip {
    font-weight: inherit;
    &:hover {
      color: #fff;
    }
  }
  &.small {
    width: 0.75rem;
    height: 0.75rem;
    line-height: 0.75rem;
    font-size: 0.55rem;
  }
}