ul.skill-list {
  list-style-type: none;
  margin-left: 0;

  li { 
     display: inline;
  }
}

.skill-pill {
  display: inline-block;
  margin-bottom: 5px;
  .skill-name {
    margin-left: -5px;
    &.label {
      padding: 0 16px 0;
      font-size: 0.6875rem;
      color: $dark-gray;
      background: $lighter-gray;
      border-radius: 0 $global-radius $global-radius 0;
    }
    line-height: 29px;
  }

  .skill-count {
    min-width: 40px !important;
    &.label {
      padding: 0 16px 0;
      font-size: 0.6875rem;
      background: $primary-color;
      border-radius: $global-radius 0 0 $global-radius;
    }
    line-height: 29px;
    .fa { font-size: rem-calc(12); }
  }
}