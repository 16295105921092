.estimate-accuracy {

  label {
    color: $medium-gray;
    font-size: 0.75rem;
    font-weight: 300;
    margin-bottom: 0.8125rem;
  }

  .progress {

    .indicator {
      i {
        font-size: 250%;
        line-height: 0 !important;
        color: $primary-color;
        padding-top: 5px;
      }
    }
  }
}
