// Footer
// vendorable.com

.footer {
  padding: 5rem 0 5rem;
  margin-top: 2rem;
  background: black;

  h6 {
    color: $primary-color;
    font-style: normal;
    font-weight: 400;
  }
  p {

    color: #fff;
    font-weight: 200;
    margin-bottom: 0;
  }

  p.inverted-link a {
    color: #fff;
    font-weight: 200;
    &:hover, &:focus { color: $primary-color; }
  }

  .copyright {
    .fa-heart { color: $primary-color; }
  }
}

// Footer media queries
@include breakpoint(small only) {

  .footer {
    padding: 3rem 0 3rem;
    text-align: center;
    h6 {margin-top: 1rem;}
  }
}