.reveal.cookie {
  @include reveal-modal-fullscreen;
  background-color: #3d4347;
  height: auto;
  min-height: auto;
  top: unset;
  z-index: 2147483002;
  p {
    color: $white;
  }
}
