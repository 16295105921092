.agents-sale, .agents-lease, .agent-aides-sale, .agent-aides-lease, .agent-profiles-show, .prospective-tenants-show, .real-property-leases-new, .real-property-leases-save-template, .real-property-leases-create, .real-property-leases-edit, .real-property-leases-update, .real-property-leases-show, .real-property-sales-new, .real-property-sales-save-template, .real-property-sales-create, .real-property-sales-edit, .real-property-sales-update, .real-property-sales-show, .real-properties-show {
  .tabs:not(.vertical) {
    border-bottom: 0;
  }
  .tabs-content:not(.vertical) {
    border-top: 1px solid $tab-content-border;
  }
  .tabs.vertical {
    @include breakpoint(large up) {
      border-right: 0;
    }
    @include breakpoint(medium down) {
      border-bottom: 0;
    }
  }
  .tabs-content.vertical {
    border-left: 1px solid $tab-content-border;
    height: 100%;
    @include breakpoint(medium down) {
      border-top: 0;
    }
  }
}
