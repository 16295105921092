// Home
// vendorable.com

// Home
// - - - - - - - - - - - - - - - - - - - - - - - - -

.home-index, .home-about, .home-press, .home-owners, .home-agents, .home-government, .home-law, .home-insolvency, .home-property-development, .home-accessibility, .home-contact {

  margin: 0;

// Styling for font awesome icons
// --------------------------------------------------------------

  .fa {
    margin-right: 25px;
    margin-bottom: 25px;
    padding: 15px;
    float: left;
    border: 1px solid #ccc;
    border-radius: 50%;
    background: #eee;
    color: black;
  }

  .fa.white {
    margin: 0;
    padding: 0;
    float: none;
    border: none;
    background: none;
    display: inline;
    color: white;
  }

  .fa-4x {
      float: none;
      display: block;
      border: none;
      background: none;
      margin-top: 50px;
      margin-bottom: 5px;
      margin-right: auto;
      margin-left: auto;
      padding: 10px 0 10px 0;
      height: 100px;
  }


  // Styling for masthead divs
  // --------------------------------------------------------------

  .masthead {
    border: none;
    @include breakpoint(medium up) {
        padding-top: 9rem;
        padding-bottom: 9rem;
      }
    @include breakpoint(small only) {
      text-align: center;
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    h1, h2, h3, h4, h5, h6, p {
      color: #fff;
    }

    h1, h2 {
      font-weight: 700;
    }

    h4, p {
      font-weight: 300;
    }

    &.city-slivers {
      @include cover-background('../images/cityslivers2.jpg', 0.5);
    }

    &.about {
      @include cover-background('../images/about-masthead.jpg', 0.6);
    }

    &.product-item {
      @include cover-background('../images/about-bottom.jpg', 0.6);
    }

    &.press {
      @include cover-background('../images/press-masthead.jpg', 0.6);
    }

    &.contact {
      @include cover-background('../images/contact-masthead.png', 0.5);
    }

    &.owners {
      @include cover-background('../images/owners-masthead.jpg', 0.6);
    }

    &.agents {
      @include cover-background('../images/agents-masthead.jpg', 0.6);
    }

    &.case-studies-government {
      @include cover-background('../images/case-studies-government.jpg', 0.6);
    }

    &.case-studies-insolvency {
      @include cover-background('../images/case-studies-insolvency.jpg', 0.6);
    }

    &.case-studies-law {
      @include cover-background('../images/case-studies-law.jpg', 0.6);
    }

    &.case-studies-property {
      @include cover-background('../images/case-studies-property.jpg', 0.6);
    }

    &.accessibility {
      @include cover-background('../images/accessibility-masthead.jpg', 0.6);
    }
  }

  // Styling for feature div
  // --------------------------------------------------------------

  .features {
    border: none;

    h4 {
      font-family: "proxima-nova",sans-serif;
      font-style: normal;
      font-weight: 700;
      color: $primary-color;
    }

    p {
      color: $medium-gray;
    }

  }

  // Styling for professional community div
  // --------------------------------------------------------------

  .community {

    @include cover-background('../images/simplicity.jpg', 0.6);

    h2 {
      font-weight: 600;
      color: #fff;
    }

    p {
      font-weight: 200;
      color: #fff
    }
  }

// Styling for credential seals and press
// ----------------------------------------------------------------

  .press {
    border: none;
  }
}

// Case studies cards
// ----------------------------------------------------------------

  .case-studies-callout {
    @include cover-background('../images/case-studies-callout.jpg', 0.8);
    margin: 2rem;
    padding: 2rem;
    max-height: 25rem;

    h2 {
      font-weight: 600;
      color: #fff;
    }

    p {
      font-weight: 200;
      color: #fff
    }

  } 

  .case-studies-card {
    margin: 2rem;
    padding: 1rem;
    max-height: 15rem;
  }

  .case-studies-tile {
    height: 10rem;

    &.case-studies-government {
      @include cover-background('../images/case-studies-government.jpg', 0);
    }

    &.case-studies-insolvency {
      @include cover-background('../images/case-studies-insolvency.jpg', 0);
    }

    &.case-studies-law {
      @include cover-background('../images/case-studies-law.jpg', 0);
    }

    &.case-studies-property {
      @include cover-background('../images/case-studies-property.jpg', 0);
    }
  }

// Press tiles 
// ------------------------------------------------------------------

  .press-tile {
    padding-right: 8rem;

    @include breakpoint(small only) {
      text-align: center;
      margin-top: 1rem;
      padding: 1rem 2rem;
    }
  }

// Team tiles
// --------------------------------------------------------------
  .team-photo {
    object-fit: cover;
    height: 15rem;
  }
