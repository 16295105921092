form {
  background: $white;
  .field_with_errors {
    border-color: get-color(alert);
  }
  #error_explanation {
    color: get-color(alert);
    font-weight: 600;
  }
}
