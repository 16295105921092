.record-panel {
  @include callout(#fff);
  padding:0;

  .top {
    font-size: 0.8125rem;
    padding: rem-calc(8 10 10);
    .fa-map-marker-alt {
        color: $dark-gray;
    }
  }

  .map {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  .configuration {
    padding: rem-calc(8 10 10);
    border-bottom: 1px solid $white;
  }

  .large-data {
    padding: rem-calc(8 10 10);
    border-bottom: 1px solid $white;
    text-align: center;

    .inner {
      .data-point {
        color: $primary-color;
        font-size: 1rem;
        font-weight: 700;
        padding: 0 rem-calc(20);
      }
      .data-label {
        color: $medium-gray;
        font-size: 1rem;
        font-weight: 300;
        max-height: rem-calc(60);
        min-height: rem-calc(60);
      }
    }
  }

  .middle {
    border-bottom: 1px solid $white;
    position: relative;
    padding: rem-calc(8 10 10);
    max-height: rem-calc(120);
    min-height: rem-calc(120);

    .inner {
      max-height: rem-calc(112);
      overflow: hidden;
      text-overflow: ellipsis;

      h6 { color: $primary-color; }
      .trix-content {
        font-weight: initial;
        margin-bottom: 0.25rem;
        font-size: 0.75rem;
      }
    }

    .overlay {
      position: absolute;
      background-image: -moz-linear-gradient(center top , transparent, #fff);
      background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, rgba(255,255,255,0)),color-stop(1, rgba(255,255,255,1)));
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
      padding: rem-calc(60) 0 0 0;
    }
  }

  .middle-card {
    padding: rem-calc(8 10 10);
  }

  .bottom {
    padding: rem-calc(20);

    span {
      line-height: 18px;
      margin-left: 0.3rem;
    }
  }
}