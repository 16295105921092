// Typography
// vendorable.com

h1.vendorable-logo,h4.vendorable-logo,h6.vendorable-logo {
  margin-bottom: 0;
  color: $primary-color;
  font-family: "pill-gothic-300mg", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
}

.main {
  ul.accordion, ul.tabs, ul.menu.simple {
    font-weight: initial;
  }
}

span.caption {  display:block;
  margin-top: 0.2rem;
}

span[data-open] {
  color: $anchor-color;
  text-decoration: $anchor-text-decoration;
  cursor: pointer;
  &:hover, &:focus {
    color: $anchor-color-hover;
    text-decoration: $anchor-text-decoration-hover;
  }
}

p.notice {
  @include border-radius($global-radius);
  padding: 0.875rem;
  font-weight: 600;
  background-color: get-color(info);
}

p.selected-file {
  border-radius: $global-radius;
  padding: 0.5rem;
  font-weight: 300;
  color: $medium-gray;
  border: $input-border;
  box-shadow: $input-shadow;
  &:empty {
    border: none;
    box-shadow: none;
  }
  &.input-group-field {
    border-radius: 0;
  }
}

.fa-star, .fa-star-half {
  color: $primary-color;
  font-size: small;
}

.fa-question-circle {
  color: $primary-color;
  font-size: smaller;
}

* {
  &.primary-color {
    color: $primary-color;
  }
}