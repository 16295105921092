.message-rw-conversations-title {
  padding-bottom: 16px;
}
.message-rw-conversations-list {
  max-height: 550px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  .message-rw-conversations-list-item {
    min-height: 92px;
    &.Mui-disabled {
      opacity: unset;
    }
  }
}
.message-rw-thread-header {
  min-height: 40px;
  padding-bottom: 16px;
  padding-left: 16px;
}
.message-rw-thread-list {
  max-height: 550px;
  min-height: 330px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.message-rw-thread-pdf-button {
  float: right;
}
