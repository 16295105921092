thead {

  .sorting, .sorting_asc, .sorting_desc {
    cursor: pointer;
  }

  .sorting:after {
    font-family: "Font Awesome 6 Free";
    content: "\f0dc";
    padding-left: 0.5rem;

  }

  .sorting_asc:after {
    color: $primary-color;
    font-family: "Font Awesome 6 Free";
    content: "\f0de";
    padding-left: 0.5rem;
  }

  .sorting_desc:after {
    color: $primary-color;
    font-family: "Font Awesome 6 Free";
    content: "\f0dd";
    padding-left: 0.5rem;
  }
}

// Table scroll mod

.table-scroll {
  padding: 0.5rem;
  table {
    width: 100%
  }
}
