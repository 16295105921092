.bids-new, .bids-edit, .rpl-bids-new, .rpl-bids-edit {
  .off-canvas {
    background: $white;
    &.position-right {
      @include breakpoint(medium up) {
        width: 500px;
      }
    }
    .map {
    	height: 300px;
    }
  }
}
