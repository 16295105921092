.profile-img-container {
  position: relative;
  display:inline-block;
  img {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: hidden;
  }

  .img-overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    @include border-radius(50%);
    background-color: rgba($primary-color, 0.5);
    
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    align-items: center;
    display: flex;
    justify-content: center;

    text-align: center;

    .inner {
      h6 {
        color: #fff;
        margin-bottom: 0;
      }
    }
  }
}
.profile-img-container:hover img {
  opacity: 0.3;
}
.profile-img-container:hover .img-overlay {
  opacity: 1;
}