// wrap images to be badged with this div
div.contains-badge {
  position: relative;
  display: table-cell;
}
// add the span element adjacent to (underneath) the img element
// and put something in the span
img + span.bottom-right-badge {
  display: block;
  text-align: center;
  line-height: 0;
  position: absolute;
  bottom: 0;
  right: 0;
}
