// User reference colours
// - - - - - - - - - - - - - - - - - - - - - - - - -

.user-reference-colour {

  &.roman-silver {
    background-color: #907F9F;
    color: #fff;
  }

  &.dark-blue-gray {
    background-color: #6C6EA0;
    color: #fff;
  }

  &.maya-blue {
    background-color: #66C7F4;
    color: #fff;
  }

  &.lavendar-grey {
    background-color: #C1CAD6;
    color: #fff;
  }

  &.middle-red-purple {
    background-color: #210B2C;
    color: #fff;
  }

  &.imperial {
    background-color: #55286F;
    color: #fff;
  }

  &.turquoise-green {
    background-color: #AAD2BA;
    color: #fff;
  }

  &.yellow-green {
    background-color: #8AC926;
    color: #fff;
  }

  &.sunglow {
    background-color: #FFCA3A;
    color: #fff;
  }

  &.beige {
    background-color: #F5F7DC;
    color: #000;
  }

  &.fulvous {
    background-color: #E28413;
    color: #fff;
  }

  &.laurel-green {
    background-color: #9EBC9E;
    color: #fff;
  }

  &.cherry-blossom-pink {
    background-color: #FFAFC5;
    color: #fff;
  }

  &.champagne-pink {
    background-color: #EFD6D2;
    color: #000;
  }

  &.primary-colour {
    background-color: $primary-color;
    color: #fff;
  }

  &.has-tip {
    font-weight: normal;
  }
}