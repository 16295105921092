@import '~trix/dist/trix';

// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.
.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }
}

.trix-content {
  .attachment--preview {
    margin: 0.6em 0;
  }
}

// Clear conflicting Foundation styles
.trix-content {
  textarea.attachment__caption-editor {
    border-radius: unset;
    background-color: unset;
    box-shadow: unset;
    font-weight: unset;
    color: #0a0a0a;
    transition: unset;
    &:focus {
      outline: none;
      border: unset;
      background-color: unset;
      box-shadow: unset;
      transition: unset;
    }
  }

  img { vertical-align: unset; }
}

//Nested list styles
.trix-content {
  ol {
    list-style-type: decimal;
    ol {
      list-style-type: lower-alpha;
      ol {
        list-style-type: lower-roman;
        ol {
          list-style-type: upper-alpha;
          ol {
            list-style-type: lower-greek;
            ol {
              list-style-type: none;
            }
          }
        }
      }
    }
  }
  ul {
    list-style-type: disc;
    ul {
      list-style-type: circle;
      ul {
        list-style-type: square;
        ul {
          list-style-type: none;
        }
      }
    }
  }
}

//Word wrap
.trix-content {
  word-wrap: break-word;
}
