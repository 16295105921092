.up-sell-panel {
  @include callout(
    $color:$body-background
  );
  border: none;

  .header {
    position: relative;
    background-color: $primary-color;

    &::before {
      left: 0;
      top: -10px;
      border-width: 0 0 10px 32px;
      border-color: transparent transparent scale-color($primary-color, $lightness: 35%) transparent;
      position: absolute;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      bottom: -30px;
    }

    h4 {
      padding: 20px;
      color: #fff;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 600;
    }
  }

  .sell-info {
    padding: 20px;
    background-color: rgba($primary-color, 0.1);
  }
}