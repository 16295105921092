.MuiTablePagination-root {
  select {
    transition: unset;
    &:focus {
      border: unset;
      background-color: rgba(0, 0, 0, 0.05);
      box-shadow: unset;
      transition: unset;
    }
    &:not([multiple]) {
      padding-top: 6px;
      padding-bottom: 7px;
    }
  }
}
.MuiTable-root {
  thead, tbody, tfoot {
    background: unset;
    border: unset;
    color: unset;
  }
  tbody, tfoot {
    tr {
      background: unset
    }
  }
}
table.MuiTable-root {
  margin-bottom: unset;
  border-radius: unset;
}

.MuiFormControl-root, .MuiTextField-root, .MuiInputBase-root {
  [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
  textarea {
    border-radius: unset;
    box-shadow: unset;
    transition: unset;
    &:focus {
      border: unset;
      background-color: unset;
      box-shadow: unset;
      transition: unset;      
    }
  }
}
