.stepper-button-group {

  .increase,
  .decrease {
    @include button(false, #fafafa, auto, $dark-gray, solid);
    border: 1px solid #ccc;
    height: rem-calc(39);

    &:hover,
    &:focus {
      border: 1px solid $primary-color;
    }

    &.is-disabled {
      background-color: #ccc;
      border: none;
      cursor: not-allowed;
    }
  }
}
