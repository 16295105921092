.loader-container {
  background-color: #{$primary-color}EE;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483001; // 1 greater than intercoms

  .loader {
    width: 90px;
    height: 30px;
    text-align: center;

    > div {
      background-color: rgba(255,255,255,0.8);
      height: 15px;
      width: 15px;
      margin-left: 3px;
      border-radius: 50%;
      display: inline-block;
      -webkit-animation: stretchdelay 0.7s infinite ease-in-out;
      animation: stretchdelay 0.7s infinite ease-in-out;
    }

    .circ2 {
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;
    }

    .circ3 {
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }

    .circ4 {
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s;
    }

    .circ5 {
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s;
    }
  }
}

@-webkit-keyframes stretchdelay {
  0%, 40%, 100% { -webkit-transform: translateY(-10px) }
  20% { -webkit-transform: translateY(-20px) }
}

@keyframes stretchdelay {
  0%, 40%, 100% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
  } 20% {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
}