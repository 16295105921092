// Profile cards
// - - - - - - - - - - - - - - - - - - - - - - - - -

.card-profile {
  @include card-container($white, $dark-gray, 0, none, 0, none);
}

.card-off-canvas {
  @include card-container($white, $dark-gray, 0, none, 0, none)
}
