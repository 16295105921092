// Devise
// vendorable.com

// Registrations new
// - - - - - - - - - - - - - - - - - - - - - - - - -

.devise-invitations-edit,
.devise-invitations-update,
.registrations-new,
.registrations-create,
.registrations-update {

  .terms-of-service-container {
    width: 100%;
    max-height: 200px;
    border: $input-border;
    padding: 1.25rem;
    overflow: auto;
    margin-bottom: 1.25rem;
  }
}