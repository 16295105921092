// Mixins
// vendorable.com

// Border radius
// - - - - - - - - - - - - - - - - - - - - - - - - -

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;

  // Usage: @include border-radius(50%);
}

// Cover background
// - - - - - - - - - - - - - - - - - - - - - - - - -

@mixin cover-background($img-url, $blackness) {
	background: linear-gradient(to right, rgba(black, $blackness), rgba(black, $blackness)), url($img-url) no-repeat center center;
	-webkit-background-size: cover;
     -moz-background-size: cover;
       -o-background-size: cover;
          background-size: cover;
}
  // Blackness: e.g. 0.5

// Profile img
// - - - - - - - - - - - - - - - - - - - - - - - - -

@mixin profile-img($width, $height, $margin) {
  width: $width;
  min-width: $width;
  height: $height;
  margin: $margin;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  @include border-radius(50%);
}

// Panel hover effect
// - - - - - - - - - - - - - - - - - - - - - - - - -

@mixin panel-hover() {

  &:focus,
  &:hover {
    box-shadow: 2px 2px 5px scale-color($secondary-color, $lightness: 20%);
  }
}
