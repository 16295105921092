.colour-picker-container {
  position: relative;

  label {
    position: absolute;
    left: rem-calc(8);
    top: rem-calc(9);
    width: rem-calc(19);
    height: rem-calc(19);
    margin-bottom: 0;
    margin-top: 0 !important;
  }

  input {
    border-radius: 0 3px 3px 0;
    padding-left: rem-calc(34);
    cursor: pointer;
    &[readonly] {
      background-color: unset;
    }
  }

  .dropdown-pane {
    padding: 0.5rem;

    div.cell {
      height: rem-calc(39);
      &:hover,
      &:focus {
        box-shadow: inset 0 0 0 2px #fff;
      }
      cursor: pointer;
    }
  }
}