.orbit-image {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: auto;
  max-height: 420px;
}

.orbit-previous, .orbit-next {
  color: $primary-color;
  cursor: pointer;
  font-size: 160%;
  border-radius: unset;
}

.orbit-bullets button {
  cursor: pointer;
}
